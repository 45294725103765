import React from 'react';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout/layout';
import Container from '../../components/container/container';
import { Grid, Col } from '../../components/grid/grid';
import Button from '../../components/button/button';

import * as styles from './properties.module.scss';

export default function PropertiesPage({ data }) {
  const properties = data.allMarkdownRemark.edges;
  return (
    <Layout title="Properties">
      <Container>
        <h1 className={`txt-primary ${styles.headline}`}>Our Properties</h1>
        <div className={styles.properties}>
          {properties.map(({ node: property }) => (
            <div className={styles.propertyWrapper} key={property.fields.slug}>
              <div className={styles.property}>
                <Link to={`/properties${property.fields.slug}`}>
                  <StaticImage
                    src="../../images/properties/property-thumb-example.png"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth"
                  />
                </Link>
                <div className={styles.property__details}>
                  <h4 className="tight txt-primary flush">
                    {property.frontmatter.title}
                  </h4>
                  <p>{property.frontmatter.location}</p>
                  <p className={`${styles.property__type} flush`}>
                    {property.frontmatter.propertyType}
                  </p>
                  <p className="txt-primary">
                    Available from {property.frontmatter.availableDate}
                  </p>
                  <Button href={`/properties${property.fields.slug}`} small>
                    Enquire here
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query PropertiesQuery {
    allMarkdownRemark {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            availableDate(formatString: "MMMM yyyy")
            location
            propertyType
            title
          }
        }
      }
    }
  }
`;
